import { Button, Space } from 'antd';
import Image from 'next/image';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import ROUTES from 'constants/routes';

function NotFound() {
  return (
    <div className="flex-center flex-column pt-100 pb-100 error-bg">
      <div className="d-flex mb-40">
        <Image src="/images/errors/error_404.svg" width={384} height={317} alt="" />
      </div>
      <p className="landing-subtitle mb-8">
        <FormattedMessage id="errors.notFound.subtitle" />
      </p>
      <h3 className="landing-title mb-8">
        <FormattedMessage id="errors.notFound.title" />
      </h3>
      <p className="landing-simple-text mb-24">
        <FormattedMessage id="errors.notFound.description" />
      </p>
      <Space size={24}>
        <Button className="min-w-140" type="primary" size="large">
          <a href={ROUTES.HOME.PATH}>
            <FormattedMessage id="errors.returnHome" />
          </a>
        </Button>
        <Button className="min-w-140" type={'secondary' as any} size="large">
          <a href={ROUTES.CONTACT_US.PATH}>
            <FormattedMessage id="static.contactUs" />
          </a>
        </Button>
      </Space>
    </div>
  );
}

export default memo(NotFound);
